<script lang="ts" setup>
// Dependencies - Vendor
import { type PropType, ref, watchEffect } from 'vue';

// Dependencies - Framework
import { buildComponentClasses } from '@/globals';

// Interfaces/Types - Button Type Identifier
type ButtonTypeId =
    | 'alert'
    | 'default'
    | 'default.caution'
    | 'default.negative'
    | 'default.neutral'
    | 'default.positive'
    | 'dropdown'
    | 'icon'
    | 'link'
    | 'link.negative'
    | 'option'
    | 'option.neutral'
    | 'option.positive'
    | 'option.caution'; // TODO: Card button type for dashboards.

// Attributes, Emitted Events, Options, Properties & Slots
const props = defineProps({
    type: { default: 'default', required: false, type: String as PropType<ButtonTypeId> }
});

// Constants
const COMMON_DISABLED_CLASSES: string = 'disabled:pointer-events-none disabled:opacity-50';
const COMMON_FOCUS_CLASSES: string = 'ring-offset-2 focus:outline-none focus:ring-2 focus:ring-neutral-300 focus:ring-offset-surface-light dark:focus:ring-neutral-600 dark:focus:ring-offset-surface-dark';
const CONFIGURATION = {
    alert: {
        base: `h-8 rounded border border-divider-light px-4 text-sm font-medium dark:border-divider-dark ${COMMON_DISABLED_CLASSES} ${COMMON_FOCUS_CLASSES}`,
        variants: { '': 'bg-transparent hover:bg-neutral-200 active:bg-backdrop-light dark:bg-transparent dark:hover:bg-neutral-700 dark:active:bg-backdrop-dark' }
    },
    dropdown: {
        base: `h-10 rounded px-3 ${COMMON_DISABLED_CLASSES} ${COMMON_FOCUS_CLASSES}`,
        variants: { '': 'bg-backdrop-light hover:bg-neutral-200 active:bg-backdrop-light dark:bg-backdrop-dark dark:hover:bg-neutral-700 dark:active:bg-backdrop-dark' }
    },
    icon: {
        base: `rounded text-sm font-medium ${COMMON_DISABLED_CLASSES} ${COMMON_FOCUS_CLASSES}`,
        variants: { '': 'bg-transparent hover:bg-neutral-200 active:bg-backdrop-light dark:bg-transparent dark:hover:bg-neutral-700 dark:active:bg-backdrop-dark' }
    },
    link: {
        base: `rounded ${COMMON_DISABLED_CLASSES}`,
        variants: {
            '': 'bg-transparent text-blue-600 hover:text-blue-800 hover:underline active:text-blue-600 dark:bg-transparent dark:text-blue-300 dark:hover:text-blue-400 dark:active:text-blue-300',
            negative: 'bg-transparent text-red-600 hover:text-red-800 hover:underline active:text-red-600 dark:bg-transparent dark:text-red-300 dark:hover:text-red-400 dark:active:text-red-300'
        }
    },
    option: {
        base: `flex items-center h-10 rounded px-0.5 ${COMMON_DISABLED_CLASSES} ${COMMON_FOCUS_CLASSES}`,
        variants: {
            '': 'bg-neutral-100 hover:bg-neutral-300 active:bg-neutral-100 data-[selected=true]:bg-slate-300 dark:bg-neutral-700 dark:hover:bg-neutral-600 dark:active:bg-neutral-700 dark:data-[selected=true]:bg-slate-600',
            caution:
                'bg-amber-200 hover:bg-amber-300 active:bg-amber-200 data-[selected=true]:bg-slate-300 dark:bg-amber-300 dark:text-neutral-900 dark:hover:bg-amber-400 dark:active:bg-amber-300 dark:data-[selected=true]:bg-slate-600',
            neutral:
                'bg-sky-200 hover:bg-sky-300 active:bg-sky-200 data-[selected=true]:bg-slate-300 dark:bg-sky-300 dark:text-neutral-900 dark:hover:bg-sky-400 dark:active:bg-sky-300 dark:data-[selected=true]:bg-slate-600',
            positive:
                'bg-green-200 hover:bg-green-300 active:bg-green-200 data-[selected=true]:bg-slate-300 dark:bg-green-300 dark:text-neutral-900 dark:hover:bg-green-400 dark:active:bg-green-300 dark:data-[selected=true]:bg-slate-600'
        }
    },
    default: {
        base: `h-9 justify-center rounded text-sm font-medium leading-tight ${COMMON_DISABLED_CLASSES} ${COMMON_FOCUS_CLASSES}`,
        variants: {
            '': 'bg-neutral-200 hover:bg-neutral-300 active:bg-neutral-200 dark:bg-neutral-700 dark:hover:bg-neutral-600 dark:active:bg-neutral-700',
            caution: 'bg-amber-200 hover:bg-amber-300 active:bg-amber-200 dark:bg-amber-300 dark:text-neutral-900 dark:hover:bg-amber-400 dark:active:bg-amber-300',
            negative: 'bg-red-200 hover:bg-red-300 active:bg-red-200 dark:bg-red-300 dark:text-neutral-900 dark:hover:bg-red-400 dark:active:bg-red-300',
            neutral: 'bg-sky-200 hover:bg-sky-300 active:bg-sky-200 dark:bg-sky-300 dark:text-neutral-900 dark:hover:bg-sky-400 dark:active:bg-sky-300',
            positive: 'bg-green-200 hover:bg-green-300 active:bg-green-200 dark:bg-green-300 dark:text-neutral-900 dark:hover:bg-green-400 dark:active:bg-green-300'
        }
    }
};

// Reactive Variables & Watchers
const classes = ref<string>(buildComponentClasses(CONFIGURATION, props.type));
watchEffect((): void => void (classes.value = buildComponentClasses(CONFIGURATION, props.type)));
</script>

<template>
    <button :class="classes" type="button">
        <slot />
    </button>
</template>
